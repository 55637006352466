export const FifthPage = () => {
  return (
    <section
      id="joao"
      className={"max-w-screen"}
      style={{
        position: "relative",
        backgroundImage: "url('/foto2.webp')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}
      ></div>
      <div
        data-aos="fade-up"
        className={
          "flex p-6 flex-col items-end self-end gap-3 container mx-auto text-center relative"
        }
      >
        <div
          className={
            "flex flex-col py-24 w-full items-end mx-auto max-w-4xl relative"
          }
        >
          <div className={"flex flex-col max-w-[450px] text-justify"}>
            <span
              className={"text-sm font-normal z-30 drop-shadow-xl"}
              style={{ color: "#B78040", textShadow: "#000 1px 0 30px" }}
            >
              Co-founder e COO da Fly Prime
            </span>

            <h2
              className={
                "text-4xl font-bold z-30 text-white text-left drop-shadow-md"
              }
              style={{ textShadow: "#000 1px 0 30px" }}
            >
              João Marcos Zanini e Ane Caroline
            </h2>

            <p
              className={
                "text-lg mt-8 z-30 text-white font-light drop-shadow-md"
              }
              style={{ textShadow: "#000 1px 0 30px" }}
            >
              João Marcos e Ane Caroline, casados, pais de dois filhos Joaquim e
              Afonso. Ao buscarmos viagens para criar memória em família,
              descobrimos o mundo das milhas aéreas e das emissões de passagens.
              Após realizar alguns de nossos sonhos de viagens, juntamente com
              paixão por empreender em Novembro de 2022 fundamos a Fly Prime
              Viagens ao lado de uma das maiores referências do mercado de
              milhas e turismo do Brasil, Luiz Gregatti.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
