import depoimento1 from "/public/depoimento1.webp";
import depoimento2 from "/public/depoimento2.webp";
import depoimento3 from "/public/depoimento3.webp";
import depoimento4 from "/public/depoimento4.webp";
import depoimento5 from "/public/depoimento5.webp";
import depoimento6 from "/public/depoimento6.webp";
import jmarcos from "/public/jmarcos.webp";
import caroline from "/public/caroline.webp";
import team1 from "/public/team1.webp";
import team2 from "/public/team2.webp";
import executive1 from "/public/executive1.webp";
import executive2 from "/public/executive2.webp";
import BlurImage from "@/components/BlurImage";

export const ThirdPage = () => {
  return (
    <section id="depoimentos" className={"bg-[#D9D9D9]"}>
      <div
        className={"mx-auto flex flex-col items-center relative gap-4"}
        data-aos="fade-up"
      >
        <h2
          className={
            "text-2xl container p-4 text-[#233259] font-semibold text-center mt-6"
          }
        >
          Viagens em Classe Executiva, Pacotes e Grupos
        </h2>

        <div className="swipe absolute z-10 top-96 md:top-56 left-1/2 -ml-24">
          <div className="path"></div>
          <div className="hand-icon"></div>
        </div>

        <div className="carousel gap-1 max-h-96">
          <div className="carousel-item">
            <BlurImage
              src={team1}
              alt={"Equipe"}
              className="object-cover w-96 h-full"
            />
          </div>
          <div className="carousel-item">
            <BlurImage
              src={team2}
              alt={"Equipe"}
              className="object-cover w-96 h-full"
            />
          </div>
          <div className="carousel-item">
            <BlurImage
              src={executive1}
              alt={"Luiz"}
              className="object-cover w-96 h-full"
            />
          </div>
          <div className="carousel-item">
            <BlurImage
              src={executive2}
              alt={"Luiz"}
              className="object-cover w-96 h-full"
            />
          </div>
          <div className="carousel-item">
            <BlurImage
              src={jmarcos}
              alt={"João Marcos"}
              className="object-cover w-96 h-full"
            />
          </div>
          <div className="carousel-item">
            <BlurImage
              src={caroline}
              alt={"Ane Caroline"}
              className="object-cover w-96 h-full"
            />
          </div>
        </div>

        <div
          className={
            "flex container p-4 flex-col text-center items-center gap-4 mx-auto max-w-6xl"
          }
        >
          <h2
            className={"text-2xl text-[#233259] font-semibold mb-4"}
            data-aos="fade-up"
          >
            Como é viajar com a gente? 👇🏼
          </h2>

          <div className="grid grid-cols-2 md:grid-cols-3 w-full max-w-5xl gap-4">
            <BlurImage
              src={depoimento1}
              width={330}
              height={500}
              alt="Depoimento"
              className="w-full h-auto duration-300 transition-all hover:scale-110 sm:grayscale hover:grayscale-0 cursor-pointer box-glow rounded-xl hover:z-10"
            />
            <BlurImage
              src={depoimento2}
              width={330}
              height={500}
              alt="Depoimento"
              className="w-full h-auto duration-300 transition-all hover:scale-110 sm:grayscale hover:grayscale-0 cursor-pointer box-glow rounded-xl hover:z-10"
            />
            <BlurImage
              src={depoimento3}
              width={330}
              height={500}
              alt="Depoimento"
              className="w-full h-auto duration-300 transition-all hover:scale-110 sm:grayscale hover:grayscale-0 cursor-pointer box-glow rounded-xl hover:z-10"
            />
            <BlurImage
              src={depoimento4}
              width={330}
              height={500}
              alt="Depoimento"
              className="w-full h-auto duration-300 transition-all hover:scale-110 sm:grayscale hover:grayscale-0 cursor-pointer box-glow rounded-xl hover:z-10"
            />
            <BlurImage
              src={depoimento5}
              width={330}
              height={500}
              alt="Depoimento"
              className="w-full h-auto duration-300 transition-all hover:scale-110 sm:grayscale hover:grayscale-0 cursor-pointer box-glow rounded-xl hover:z-10"
            />
            <BlurImage
              src={depoimento6}
              width={330}
              height={500}
              alt="Depoimento"
              className="w-full h-auto duration-300 transition-all hover:scale-110 sm:grayscale hover:grayscale-0 cursor-pointer box-glow rounded-xl hover:z-10"
            />
          </div>

          <h2
            className={
              "text-lg md:text-2xl text-[#233259] font-semibold my-8 max-w-3xl"
            }
            data-aos="fade-up"
          >
            Nos siga no Instagram e veja todos os depoimentos de quem já viveu a
            experiência Prime.
          </h2>
        </div>
      </div>
    </section>
  );
};
