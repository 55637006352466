import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { InputMask } from "@react-input/mask";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Modal, { useModal } from "@/components/modal";

export const Destiny: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [userWhatsapp, setUserWhatsapp] = useState<string>("");
  const [gastos, setGastos] = useState<string>("");

  const secondSchema = yup.object().shape({
    userName: yup.string().required("Por favor, informe o seu nome."),
    userEmail: yup
      .string()
      .email("Por favor, informe um e-mail válido.")
      .required("Por favor, informe o seu e-mail."),
    userWhatsapp: yup
      .string()
      .required("Por favor, informe o seu número de telefone.")
      .matches(
        /^\(\d{2}\) \d{5}-\d{4}$/,
        "Por favor, informe um número de telefone válido (exemplo: (99) 99999-9999)."
      ),
  });

  function sendWhatsapp() {
    const linkWhatsapp = `https://wa.me/5518996505452?text=Nome: ${encodeURIComponent(
      userName
    )}%0AE-mail: ${encodeURIComponent(
      userEmail
    )}%0AWhatsapp: ${encodeURIComponent(userWhatsapp)}${
      gastos ? `%0AGasto%20m%C3%A9dio: ${encodeURIComponent(gastos)}` : ""
    }`;

    window.open(linkWhatsapp, "_blank");
  }

  const handleQuotation = async () => {
    //chama api addToList.js
    const data = {
      name: userName,
      email: userEmail,
      whatsapp: userWhatsapp,
      gastos,
    };

    const secondData = {
      userName,
      userEmail,
      userWhatsapp,
    };

    setLoading(true);

    try {
      // Validação dos dados do formulário
      await secondSchema
        .validate(secondData, { abortEarly: false })
        .then(async () => {
          await fetch("/api/addToList", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          });

          setLoading(false);
          //clear form
          setUserName("");
          setUserEmail("");
          setUserWhatsapp("");
          setGastos("");
          sendWhatsapp();
        });
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const validationErrors = error.inner.map((error) => error.message);
        validationErrors.forEach((message) => {
          toast.error(message);
        });
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (loading) {
      onOpen();
    } else {
      onClose();
    }
  }, [loading]);

  const { ref, onOpen, onClose } = useModal();

  const handleGastos = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setGastos(e.target.value);
  };

  return (
    <>
      <ToastContainer />

      <Modal ref={ref} onClose={onClose}>
        <div className="flex flex-col items-center justify-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      </Modal>

      <div
        className={
          "pb-12 mt-8 flex flex-col mx-auto p-3 text-center gap-4 max-w-6xl items-center"
        }
        id="emitir-passagens"
      >
        {/*<h2 className={'text-4xl font-normal text-white'}>Algum destino em mente?</h2>*/}

        <span
          className={"text-sm md:text-xl text-center font-light text-white"}
        >
          Preencha os dados abaixo e ganhe uma consultoria individual de até 1
          hora!
        </span>

        <div
          className={
            "grid sm:grid-cols-5 gap-4 w-full bg-white/25 backdrop-blur-md p-6 rounded border border-primary border-solid z-10"
          }
        >
          <div className="relative flex flex-col gap-1">
            <h2 className="text-xs font-normal text-white text-left">Nome</h2>
            <input
              name="name"
              id="name"
              className="iata input border-primary col-span-1 border w-full text-sm bg-white text-gray-600 max-h-10"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder={"Nome"}
            ></input>
          </div>

          <div className="relative flex flex-col gap-1">
            <h2 className="text-xs font-normal text-white text-left">E-mail</h2>
            <input
              name="email"
              id="email"
              className="iata input border-primary col-span-1 border w-full text-sm bg-white text-gray-600 max-h-10"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              placeholder={"E-mail"}
            ></input>
          </div>

          <div className="relative flex flex-col gap-1">
            <h2 className="text-xs font-normal text-white text-left">
              Whatsapp
            </h2>
            <InputMask
              mask="(__) _____-____"
              replacement={{ _: /\d/ }}
              name="whatsapp"
              id="whatsapp"
              value={userWhatsapp}
              onChange={(e) => setUserWhatsapp(e.target.value)}
              className="iata input border-primary col-span-1 border w-full text-sm bg-white text-gray-600 max-h-10"
              placeholder="(XX) 9XXXX-XXXX"
            />
          </div>

          <div className="relative flex flex-col gap-1">
            <h2 className="text-xs font-normal text-white text-left">
              Gasto médio mensal
            </h2>
            <select
              name="gasto"
              id="gasto"
              className="iata input border-primary col-span-1 border w-full text-sm bg-white text-gray-600 max-h-10"
              onChange={(e) => handleGastos(e)}
            >
              <option value="">Não Opinar</option>
              <option value="Abaixo de R$ 10.000">Abaixo de R$ 10.000</option>
              <option value="De R$ 10.000 a R$ 20.000">
                De R$ 10.000 a R$ 20.000
              </option>
              <option value="De R$ 20.000 a R$ 50.000">
                De R$ 20.000 a R$ 50.000
              </option>
              <option value="Mais de R$ 50.000">Mais de R$ 50.000</option>
            </select>
          </div>

          <button
            className={"btn btn-primary rounded self-end text-white mb-1"}
            onClick={handleQuotation}
          >
            Ver cotação
          </button>
        </div>
      </div>
    </>
  );
};
