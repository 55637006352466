import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import SpanIcon from "/public/spanIcon.png";
import flyprime from "/public/flyprime.webp";
import BlurImage from "@/components/BlurImage";
import Cadastur from "/public/logo-cadastur.webp";
import Clouflare from "/public/logo-cloudflare.webp";
import SSL from "/public/logo-ssl.webp";

export const Footer = () => {
  return (
    <section
      id="contato"
      className={"relative mx-auto pb-12 p-6 bg-black text-white"}
    >
      <div
        className={"grid w-full mx-auto items-center relative gap-4"}
        data-aos="fade-up"
      >
        <div
          className={
            "flex flex-col text-center justify-center items-center align-center gap-6"
          }
        >
          <h2 className={"text-3xl font-semibold"}>Onde estamos localizados</h2>
          <iframe
            title="Localização do evento"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d608.8226523429556!2d-50.43578249255594!3d-21.202144899257902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x949643fb805b4899%3A0x95fc37da8180101c!2sR.%20Bandeirantes%2C%2092%20-%20Jardim%20Sumare%2C%20Ara%C3%A7atuba%20-%20SP%2C%2016640-025!5e0!3m2!1spt-BR!2sbr!4v1736358074353!5m2!1spt-BR!2sbr"
            className={"w-full h-96 rounded border-primary border-2"}
          ></iframe>
        </div>

        <div
          className={"flex flex-col gap-1 items-center mt-10"}
          data-aos="fade-up"
        >
          <h2 className={"text-white text-xl font-light"}>
            Ficou com qualquer dúvida?
          </h2>
          <p className={"text-xs text-white font-light"}>
            Pode acionar meu time de especialistas!
          </p>

          <a
            href="https://wa.me/5518996505452"
            className="btn btn-primary mt-4 relative z-10 !font-semibold"
            id="ajuda"
          >
            <MarkChatUnreadIcon />
            FALAR COM UM AGENTE
            <BlurImage
              src={SpanIcon}
              alt={"Span"}
              width={16}
              height={16}
              className="absolute span-icon animate-pulse"
              style={{ top: "-6px", right: "-6px", transformOrigin: "50% 50%" }}
            />
          </a>
        </div>
      </div>
      <BlurImage
        src={flyprime}
        alt={"Localização do evento"}
        className={"absolute bottom-0 right-0 max-w-2xl z-0"}
      />

      <div className="flex items-center flex-col md:flex-row gap-4 w-full md:w-auto justify-center rounded-xl p-4 mt-12">
        <BlurImage
          src={Cadastur}
          width={130}
          height={65}
          alt="logo"
          className={"object-contain h-12 rounded-md py-1 px-2 bg-white"}
        />

        <BlurImage
          src={Clouflare}
          width={100}
          height={65}
          alt="logo"
          className={"object-contain h-12 rounded-md py-1 px-2 bg-white"}
        />

        <BlurImage
          src={SSL}
          width={80}
          height={65}
          alt="logo"
          className={"object-contain h-12 rounded-md py-1 px-2 bg-white"}
        />
      </div>

      <span className={"text-sm font-normal text-center block mt-6"}>
        Rua Bandeirantes, 92 Araçatuba - SP, CEP: 16.640-025.
        <br />
        CNPJ: 51.711.135/0001-04
      </span>
    </section>
  );
};
