export const FourthPage = () => {
  return (
    <section
      id="luiz"
      className={"max-w-screen"}
      style={{
        position: "relative",
        backgroundImage: "url('/foto1.webp')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}
      ></div>

      <div
        data-aos="fade-up"
        className={
          "flex p-6 flex-col items-end self-end gap-3 container mx-auto text-center relative"
        }
      >
        <div
          className={
            "flex flex-col py-24 items-end w-full mx-auto max-w-4xl relative"
          }
        >
          <div className={"flex flex-col max-w-[450px] text-justify"}>
            <span
              className={"text-sm font-normal z-30 drop-shadow-xl"}
              style={{ color: "#B78040", textShadow: "#000 1px 0 30px" }}
            >
              Founder e CEO da Fly Prime
            </span>

            <h2
              className={"text-4xl font-bold z-30 text-white drop-shadow-md"}
              style={{ textShadow: "#000 1px 0 30px" }}
            >
              Luiz Gregatti
            </h2>

            <p
              className={
                "text-lg mt-8 z-30 drop-shadow-md text-white font-light"
              }
              style={{ textShadow: "#000 1px 0 30px" }}
            >
              Luiz Gregatti, empresário, natural de Birigui/SP. Foi buscando uma
              passagem de volta da Alemanha, onde eu morava em 2019 que conheci
              o universo das milhas e das emissões de passagens. De lá pra cá
              dividi palcos com Pablo Marçal, Flávio Augusto e Ícaro de
              Carvalho, acumulei e gerenciei mais de 60 milhões de milhas,
              32.000 alunos e clientes passaram por algum programa de nossa
              empresa Fly Educacional, que conta hoje com um time de mais de 45
              colaborares. Sou apaixonado por histórias, viagens e negócios. Em
              novembro de 2022 fundamos mais um braço do nosso grupo a Fly Prime
              Viagens.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
