import { InputMask } from "@react-input/mask";
import { SetStateAction, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import fb from "../../utils/fb";

interface ILead {
  eventId?: string;
  pagina?: string;
  value?: number;
  redirectTo?: string | undefined;
  params?: object;
}

export default function Lead({
  redirectTo,
  eventId,
  pagina,
  value = 0,
}: ILead) {
  const [nameLead, setNameLead] = useState("");
  const [emailLead, setEmailLead] = useState("");
  const [whatsappLead, setWhatsappLead] = useState("");
  const [viagens, setViagens] = useState("Não costuma viajar");
  const [gasto, setGasto] = useState("Gasto De 8 a 10k");
  const [loading, setLoading] = useState(false);

  // const sendDataToGtag = () => {
  //     if (typeof window !== 'undefined' && typeof gtag === 'function') {
  //         gtag('event', 'lead', {
  //             send_to: '${process.env.NEXT_PUBLIC_GA_TRACKING_ID}/Ve6JCPK2gMIZEKjwhf89',
  //         })
  //         gtag('set', 'user_data', {
  //             email: emailLead,
  //             phone_number: whatsappLead,
  //         })
  //     }
  // }

  const [utmObj, setUtmObj] = useState({
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_term: "",
    utm_content: "",
  });

  useEffect(() => {
    //get cookies
    const utm = document.cookie.match(new RegExp("(?:^|; )@Prime:UTM=([^;]*)"));

    try {
      if (utm) {
        setUtmObj(JSON.parse(decodeURIComponent(utm[1])));
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const schema = yup.object().shape({
    nameLead: yup.string().required("Por favor, informe o seu nome."),
    emailLead: yup
      .string()
      .email("Por favor, informe um e-mail válido.")
      .required("Por favor, informe o seu e-mail."),
    whatsappLead: yup
      .string()
      .required("Por favor, informe o seu número de telefone.")
      .matches(
        /^\(\d{2}\) \d{5}-\d{4}$/,
        "Por favor, informe um número de telefone válido (exemplo: (99) 99999-9999)."
      ),
    viagens: yup
      .string()
      .required(
        "Por favor, informe quantas viagens você costuma fazer ao ano."
      ),
    gasto: yup
      .string()
      .required("Por favor, informe qual o gasto mensal de sua família."),
  });

  function handleInputChangeLead(event: {
    target: { value: SetStateAction<string> };
  }) {
    setWhatsappLead(event.target.value);
  }

  function sendPixel() {
    fb("Lead", "Lead" + eventId, nameLead, emailLead, whatsappLead);
    // sendDataToGtag()

    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }

  const handleSubmitLead = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setLoading(true);

    try {
      await schema.validate(
        { nameLead, emailLead, whatsappLead, viagens, gasto },
        { abortEarly: false }
      );

      const data = {
        name: nameLead,
        email: emailLead,
        whatsapp: whatsappLead,
        tags: `${pagina},${viagens},${gasto},source:${utmObj.utm_source}, medium:${utmObj.utm_medium}, campaign:${utmObj.utm_campaign}, term:${utmObj.utm_term}, content:${utmObj.utm_content}`,
        price: value.toString(),
      };

      const url = process.env.NEXT_PUBLIC_VIAJAFLUX_API ?? "";

      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + process.env.NEXT_PUBLIC_VIAJAFLUX_TOKEN,
        },
        body: JSON.stringify({ ...data }),
      });

      //BREVO
      await fetch("/api/addToList", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: nameLead,
          email: emailLead,
          whatsapp: whatsappLead,
          pagina,
        }),
      });

      setLoading(false);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const validationErrors = error.inner.map((error) => error.message);
        validationErrors.forEach((message) => {
          toast.error(message);
        });
        setLoading(false);
      }
    }

    sendPixel();
  };

  return (
    <>
      <ToastContainer />
      <label htmlFor="lead"></label>
      <input type="checkbox" id="lead" className="modal-toggle" />
      <label
        htmlFor="lead"
        className="modal modal-bottom sm:modal-middle z-[99999]"
      >
        <label htmlFor="" className="modal-box relative box-red-glow">
          <label
            htmlFor="lead"
            className="btn btn-ghost btn-circle absolute right-3 top-3"
          >
            ✕
          </label>
          <h3 className="font-bold text-2xl text-error">Inscrição</h3>
          <p className={"text-xs text-base-content"}>
            Preencha seus dados abaixo para receber a apresentação completa do
            nosso serviço de Gestão de Milhas ✌
          </p>
          <form
            id="formLead"
            className="text-xs py-4 grid gap-2"
            onSubmit={handleSubmitLead}
          >
            <input
              type="text"
              name="name_lead"
              placeholder="Nome Completo"
              className="text-xs input input-bordered w-full bg-white text-black"
              value={nameLead}
              onChange={(event) => setNameLead(event.target.value)}
            />
            <input
              type="email"
              name="email_lead"
              placeholder="E-mail"
              className="text-xs input input-bordered w-full bg-white text-black"
              value={emailLead}
              onChange={(event) => setEmailLead(event.target.value)}
            />

            <InputMask
              mask="(__) _____-____"
              replacement={{ _: /\d/ }}
              value={whatsappLead}
              name="whatsapp_lead"
              onChange={handleInputChangeLead}
              className="text-xs input input-bordered w-full bg-white text-black"
              placeholder="(XX) 9XXXX-XXXX"
            />

            <label htmlFor="viagens" className={"text-base-content"}>
              Quantas viagens de avião você costuma fazer ao ano?
            </label>
            <select
              name="viagens"
              className="text-xs select input-bordered w-full bg-white text-black"
              defaultValue="Não costuma viajar"
              onChange={(event) => setViagens(event.target.value)}
            >
              <option value="Não costuma viajar">Não costumo viajar</option>
              <option value="1 viagem / ano">1 viagem</option>
              <option value="2 viagens / ano">2 viagens</option>
              <option value="3 a 5 viagens / ano">3 a 5 viagens</option>
              <option value="Acima de 5 viagens / ano">
                Acima de 5 viagens
              </option>
            </select>

            <label htmlFor="gasto" className={"text-base-content"}>
              Qual o gasto mensal de sua família? (para calcularmos as viagens
              de graça que você está perdendo)
            </label>
            <select
              name="gasto"
              className="text-xs select input-bordered w-full bg-white text-black"
              defaultValue="Gasto De 8 a 10k"
              onChange={(event) => setGasto(event.target.value)}
            >
              <option value="Gasto De 8 a 10k">Abaixo de 10 mil</option>
              <option value="Gasto De 10 a 15k">De 10 a 20 mil</option>
              <option value="Gasto De 15 a 20k">De 20 a 50 mil</option>
              <option value="Gasto Acima de 20k">Acima de 50 mil</option>
            </select>

            <div className="modal-action flex justify-between">
              <button
                form="formLead"
                type="submit"
                className="btn !font-semibold btn-block bg-green-500 !h-12 text-white"
                disabled={loading}
              >
                {loading && (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                Continuar
              </button>
            </div>
          </form>
        </label>
      </label>
    </>
  );
}
